<template>
    <div>
        <b-row class="mb-3">
            <b-col>
                <span class="card-title">Roles</span>
            </b-col>
            <b-col class="text-right">
                <b-button variant="success" class="ml-auto" v-if="checkPermission('roles-admin')" @click="showSave()">
                    <b-icon icon="plus-circle" style="cursor: pointer;"></b-icon> Nuevo
                </b-button>
            </b-col>
        </b-row>
        <b-row class="mb-3">
            <b-col>
                <b-table fixed bordered small :items="items" :fields="fields" responsive head-variant="dark"
                    class="custom-table">
                    <template #cell(acciones)="data" v-if="checkPermission('roles-admin')">
                        <b-icon class="mr-2" @click="showSave(data.item)"
                            :icon="checkPermission('roles-admin') ? 'pencil-square' : 'eye'" font-scale="1.5"
                            style="cursor: pointer;"></b-icon>
                        <b-icon variant="danger" class="ml-2" @click="deleteItem(data.item)" icon="trash" font-scale="1.5"
                            style="cursor: pointer;" v-if="checkPermission('roles-admin')"></b-icon>
                    </template>
                    <template #cell(activo)="data">
                        <b-form-checkbox disabled switch v-model="data.item.activo" value="1"></b-form-checkbox>
                    </template>
                </b-table>
            </b-col>
        </b-row>
        <b-modal id="modal-save-role" size="lg" hide-header hide-footer>
            <SaveRole @closeModal="closeModal()" :data="roleSelected"></SaveRole>
        </b-modal>
    </div>
</template> 
  
<script>
import RolesService from '@/services/RolesService';
import { showMessageBox } from '../../shared/utils'
import SaveRole from './Crear.vue'

export default {
    components: {
        SaveRole
    },
    data: () => ({
        items: [],
        fields: [
            'nombre',
            { key: 'activo', thStyle: { width: "10%" } },
            { key: 'acciones', label: '', thStyle: { width: "10%" } }
        ],
        roleSelected: null

    }),
    mounted() {
        this.getRoles()
    },
    methods: {
        async getRoles() {
            this.items = await RolesService.getAll();
        },
        async showSave(role) {
            if (role) {
                let response = await RolesService.getRole(role.codigo)
                this.roleSelected = response;
            } else {
                this.roleSelected = null;
            }
            this.$bvModal.show('modal-save-role')
        },
        async deleteItem(role) {
            let rta = await showMessageBox(this, 'Confirmar eliminación', 'Confirma que desea eliminar este item?');
            if (rta) {
                RolesService.deleteRole(role.codigo).then(rta => {
                    this.$newSnackbarSuccess(rta.message)
                    this.getRoles()
                }).catch(error => {
                    this.$newSnackbarError(error.message)
                })
            }
        },
        closeModal() {
            this.$bvModal.hide('modal-save-role')
            this.getRoles()
        }
    },
    watch: {

    },
};
</script>
  
<style lang="scss" scoped></style>