<template>
    <div id="chat-container">
        <div id="panel-chat-left">
            <div id="panel-search-top-left">
                <b-input-group v-if="!searchingUser">
                    <b-form-input class="mr-3" placeholder="Buscar un chat" v-model="searchChat"></b-form-input>
                    <b-input-group-append>
                        <b-icon title="Nuevo chat" icon="person-plus-fill" scale="1.5" class="my-auto mr-2"
                            style="cursor: pointer;" @click="searchingUser = true; searchChat = ''" variant="info"></b-icon>
                    </b-input-group-append>
                </b-input-group>
                <b-input-group v-else>
                    <b-form-input placeholder="Buscar un usuario" class="mr-3" v-model="searchUser"></b-form-input>
                    <b-input-group-append>
                        <b-icon icon="x-circle" scale="1.5" class="my-auto mr-2" style="cursor: pointer;"
                            @click="searchingUser = false; searchUser = ''"></b-icon>
                    </b-input-group-append>
                </b-input-group>
            </div>
            <div id="conversations-list">
                <div v-if="!searchingUser">
                    <b-list-group>
                        <b-list-group-item :class="chat.unreadMessage ? 'item-chats flex-column align-items-start unread': 'item-chats flex-column align-items-start'"
                            v-for="chat in listConversationsFiltered" :key="chat.id" @click="setChatSelected(chat)">
                            <!-- <div class="d-flex w-100 justify-content-start"> -->
                            <div class="d-flex w-100 justify-content-start pl-1">
                                <b-icon icon="people-fill" variant="success" scale="1.2" v-if="chat.isGroup"
                                    class="mr-3"></b-icon>
                                <p class="mb-1" style="font-size: 0.9rem;">
                                    {{ chat.nombre }}</p>
                                <b-icon class="ml-auto text-right" icon="chat-left-quote" variant="danger" scale="1.2" v-if="chat.unreadMessage"></b-icon>
                            </div>
                            <p class="text-right m-0 p-0" style="font-size: .6rem;">
                                {{ chat.fecha |
                                date }}</p>
                            <!-- </div> -->
                        </b-list-group-item>
                    </b-list-group>
                </div>
                <div v-else>
                    <b-list-group class="ml-0">
                        <b-list-group-item class="item-chats flex-column align-items-start"
                            v-for="user in listUsersFiltered" :key="user.document" @click="newChat(user)">
                            <div class="d-flex w-100 justify-content-start">
                                <div class="d-flex w-100 justify-content-between">
                                    <p class="mb-1">{{ user.nombre }}</p>
                                </div>
                            </div>
                        </b-list-group-item>
                    </b-list-group>
                </div>
            </div>
        </div>
        <div id="panel-chat-right">
            <div id="chat-header">
                <p v-if="chatSelected">Conversación con: <span v-if="chatSelected && chatSelected.nombre">{{
                    chatSelected.nombre }}</span></p>
                <p v-else>Seleccione un chat</p>
            </div>
            <div id="chats-messages-container" v-if="chatSelected">
                <div ref="chatMessages" id="chat-messages">
                    <div class="ml-4 mr-4" v-for="messageUser in chatMessages" :key="messageUser.id">
                        <MessageChat :messageData="messageUser"></MessageChat>
                    </div>
                </div>
                <b-input-group>
                    <b-form-input v-model="messageToSend" @keyup.enter="sendMessage()"
                        placeholder="Escribir un mensaje"></b-form-input>
                    <b-button class="ml-2 btn-send-message" @click="sendMessage()">Enviar</b-button>
                </b-input-group>
            </div>
        </div>
    </div>
</template>

<script>

import UsersService from '@/services/UsersService';
import ChatService from '../../services/ChatService'
import MessageChat from './components/MessageChat.vue'
import moment from 'moment'

export default {
    components: {
        MessageChat
    },
    data: () => ({
        messageToSend: '',
        searchingUser: false,
        searchChat: '',
        searchUser: '',
        listUsers: []
    }),
    computed: {
        chatMessages() {
            return this.$store.state.chatMessages;
            // if (this.$store.state.chatSelected) {
            //     let conversation = this.$store.state.chatConversations.find(e => e.id == this.$store.state.chatSelected.id)
            //     return conversation.messages ?? []
            // }
            // return []
        },
        chatConversations() {
            return this.$store.state.chatConversations;
        },
        listConversationsFiltered() {
            if (this.searchChat != '') {
                return this.chatConversations.filter(e => e.nombre.includes(this.searchChat.toUpperCase()))
            }
            return this.chatConversations;
        },
        listUsersFiltered() {
            if (this.searchUser != '') {
                return this.listUsers.filter(e => e.nombre.includes(this.searchUser.toUpperCase()))
            }
            return this.listUsers;
        },
        chatSelected() {
            return this.$store.state.chatSelected;
        }
    },
    created() {

    },
    mounted() {
        this.getListConversations()
        this.getListUsers()
    },
    updated() {
        if (this.$refs.chatMessages) {
            const scrollContainer = this.$refs.chatMessages;
            scrollContainer.scrollTop = scrollContainer.scrollHeight;
        }
    },
    methods: {
        async getListConversations() {
            await this.$store.dispatch('setChatConversations')
        },
        async getListUsers() {
            this.listUsers = await UsersService.getAll()
        },
        async setChatSelected(chat) {
            this.$store.dispatch('setChatSelected', chat)
        },
        async newChat(userData) {
            
            let chatFound = this.listConversationsFiltered.find(e => e.userId == userData.documento)
            if(chatFound){
                this.setChatSelected(chatFound)
            }else{
                let newChat = {
                    fecha: null,
                    id: null,
                    nombre: userData.nombre,
                    userId: userData.documento,
                    isGroup: false
                }
                this.setChatSelected(newChat)
            }
        },
        async sendMessage() {
            try {
                if (this.messageToSend == '') {
                    return;
                }
                const textMessage = this.messageToSend;
                this.messageToSend = '';
                if (!this.$store.state.socket.isConnected) {
                    this.$newSnackbarError('No se ha podido enviar el mensaje. Intenta de nuevo en un momento.')
                    return;
                }
                ChatService.insertChatMessage({
                    fromUserId: this.$store.state.userLogged.documento,
                    toUserId: this.chatSelected.userId,
                    message: textMessage,
                    conversationId: this.chatSelected.id ?? null,
                }).then(async response => {
                    if (response.insertedData) {
                        try {
                            this.$store.dispatch('emitMessageChatToSocket',{
                                message: response.insertedData.message,
                                fromUserId: response.insertedData.fromUserId,
                                toUserId: response.insertedData.toUserId,
                                dateSent: response.insertedData.createdAt,
                                fromUserName: response.insertedData.fromUserName,
                                id: response.insertedData.id,
                                conversationId: response.insertedData.conversation_id,
                                conversationDate: response.insertedData.conversationDate
                            })
                            await this.$store.dispatch('setChatConversations')
                            let chatFound = this.listConversationsFiltered.find(e => e.id == response.insertedData.conversation_id)
                            this.setChatSelected(chatFound)
                        } catch (error) {
                            console.log('error catch emit', error)
                        }
                    }
                }).catch(error => {
                    console.error('Error enviando mensaje',error)
                    this.$newSnackbarError('No se ha podido enviar el mensaje. Intenta de nuevo en un momento.')
                })
            } catch (error) {
                console.log('error socket', error)
            }
        }
    },
    watch: {

    },
    filters: {
        date(value) {
            if (moment.duration(moment().diff(moment(value))).asDays() > 1) {
                return moment(value).format('YYYY-MM-DD hh:mm:ss a')
            } else {
                return moment(value).format('hh:mm:ss a')
            }
        }
    }
};
</script>

<style lang="scss" scoped>
@import '../../app.scss';


#chat-container {
    background-color: transparent;
    display: flex;
    flex-direction: row;
    height: 80vh;
}

#panel-search-top-left {
    width: 100%;
}

#panel-chat-left {
    height: 70vh;
    min-width: 40%;
    margin: 2px;
}

#conversations-list {
    overflow-y: scroll;
    height: 70vh;
}

#conversations-list>div {
    margin-top: 15px;
}

#panel-chat-right {
    min-width: 60%;
    height: 90vh;
    margin: 5px;
}

#chats-messages-container {
    height: 70vh;
    width: 100%;
}

#chat-header {
    color: white;
    background-color: $adx-primary;
    height: 2.7rem;
    margin: 0;
    padding: 9px;
}

.card-body {
    padding: 0 !important;
    margin: 0 !important;
    background-color: none !important;
}

#chat-messages {
    margin: 0;
    padding: 10px;
    overflow-y: scroll;
    height: 100%;
    width: 100%;
}

#input-message {
    margin: 10px;
}

.input-search-chat {
    margin-left: auto;
    margin-right: auto;
    margin-top: 1.2rem;
    margin-bottom: 1.2rem;
    width: 90%;
}

.item-chats {
    border: none;
    cursor: pointer;
    padding-left: 10px;
    padding-right: 10px;
    width: 100%;
    height: 55px;
}

.item-chats:not(:last-child)::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: auto;
    right: auto;
    width: 90%;
    border-bottom: 1px solid #f0eded;
    /* Estilo del borde */
}

.item-chats:hover {
    margin: 0;
    background-color: $adx-primary;
    color: white;
}

.btn-send-message {
    background-color: $adx-primary;
}

.btn-send-message:hover {
    background-color: $adx-primary;
}



/* @media (max-height: 600px) {
    #chat-container {
        min-height: 400px;
    }

    #chats-list-container {
        min-height: 400px;
    }

    #chats-messages-container {
        min-height: 400px;
    }

    .card-body {
        height: 400px;
    }

    #chats-list-container .card-body {
        height: 40%;
    }

}
 */
</style>