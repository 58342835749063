<template>
    <div>
        <div class="mb-2 d-flex flex-row-reverse" v-if="$store.state.userLogged.documento == messageData.fromUserId">
            <div class="text-right">
                <div class="buble-chat message-out break-words">
                    <!-- {{ messageData.message }} -->
                    <p class="m-0 p-0" style="word-wrap: break-word;">{{ messageData.message }}</p>
                </div>
                <p class="m-0 p-0 text-muted text-right" style="font-size: .6875rem;">{{
                    dateMessage(messageData.dateCreated) }}</p>
            </div>
        </div>
        <div class="mb-2 d-flex flex-row" v-else>
            <div>
                <p style="font-size: .6875rem;" class="m-0 p-0">{{ messageData.fromUserName }}</p>
                <div class="buble-chat message-in break-words">
                    <!-- {{ messageData.message }} -->
                    <p class="m-0 p-0">{{ messageData.message }}</p>
                </div>
                <p class="m-0 p-0 text-muted" style="font-size: .6875rem;">{{ dateMessage(messageData.dateCreated) }}</p>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment'
export default {
    props: {
        messageData: null,
        fromUserName: null
    },
    components: {
    },
    computed: {

    },
    data: () => ({

    }),
    mounted() {
    },
    methods: {
        dateMessage(date) {
            return moment(date).format('YYYY-MM-DD hh:mm a')
        }
    },
    watch: {

    },
};
</script>

<style scoped>
.buble-chat {
    padding: 5px 10px;
    border-radius: 8px;
    font-size: 1rem;
    max-width: 100%;
}

.message-out {
    background-color: #29279e;
    color: white;
    /* float: right; */
}

.message-in {
    background-color: #f0efef;
}

.break-words {
    text-wrap: wrap;
    word-wrap: break-word;
}
</style>