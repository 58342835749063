<template>
    <b-card style="border: none; padding: 5px 25px;">
        <b-row>
            <b-col class="p-0">
                <span class="card-title">{{ isEditing ? 'Modificar' : 'Crear' }} Role</span>
            </b-col>
        </b-row>
        <b-row>
            <b-col><b>Activo:</b>
                <b-form-group>
                    <b-form-checkbox :disabled="!checkPermission('roles-admin')" v-model="form.activo"
                        switch></b-form-checkbox>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12">
                <label for=""><b>Nombre:</b></label>
                <b-form-group>
                    <b-form-input :disabled="!checkPermission('roles-admin')" v-model="form.nombre"></b-form-input>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <label for=""><b>Acceso a los siguientes grupos:</b></label>
                <multiselect size="sm" v-model="form.chatGroups" :options="chatGroupsList" multiple :show-labels="true"
                    track-by="codigo" searchable :close-on-select="true" :clear-on-select="false" label="nombre"
                    placeholder="Seleccione uno o varios grupos de chat">
                </multiselect>
            </b-col>
        </b-row>
        <b-row class="mb-1">
            <b-col>
                <label for=""><b>Permisos:</b></label>
            </b-col>
        </b-row>
        <b-row class="mb-1">
            <b-col cols="12" md="6" v-for="menu in  permissionsList " :key="menu.id">
                <b-card class="mb-2">
                    <label for=""><b>{{ menu.visualName }}</b></label>
                    <b-form-group>
                        <b-form-checkbox-group v-model="menu.itemsSelected" switches stacked
                            :disabled="!checkPermission('permissions-admin')"
                            :options="menu.options"></b-form-checkbox-group>
                    </b-form-group>
                </b-card>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="text-right">
                <b-button variant="primary" class="ml-auto" @click="saveItem()" :disabled="!activeSave"
                    v-if="checkPermission('permissions-admin') && checkPermission('roles-admin')">Guardar</b-button>
            </b-col>
        </b-row>
    </b-card>
</template>
  
<script>
import Multiselect from 'vue-multiselect'
import RolesService from '@/services/RolesService'
import ChatService from '@/services/ChatService'
import PermissionsService from '../../services/PermissionsService';
// import { showMessageBox } from '../../shared/utils'

export default {
    props: {
        data: null
    },
    components: {
        Multiselect
    },
    data: () => ({
        form: {
            nombre: '',
            activo: true,
            chatGroups: []
        },
        isEditing: false,
        permissionsList: [],
        chatGroupsList: []
    }),
    async mounted() {
        await this.getGroups()
        await this.getAllPermissions()
        if (this.data) {
            this.isEditing = true
            this.form = {
                nombre: this.data.nombre,
                activo: !!this.data.activo,
                chatGroups: this.mapGroups(this.data.chatGroups)
            }
        }
        this.mapPermissions()
    },
    computed: {
        activeSave() {
            if (!this.form.nombre) {
                return false;
            }
            return true
        }
    },
    methods: {
        async saveItem() {
            try {
                let permissionsSelected = []
                this.permissionsList.forEach(permission => {
                    permission.itemsSelected.forEach(element => {
                        permissionsSelected.push(element)
                    });
                })
                let dataToSave = {
                    nombre: this.form.nombre,
                    activo: this.form.activo,
                    permissions: permissionsSelected,
                    chatGroups: (this.form.chatGroups.map(e => e.codigo)).join(',')
                }
                let response = null;
                if (this.isEditing) {
                    response = await RolesService.updateRole(dataToSave, this.data.codigo);
                } else {
                    response = await RolesService.saveRole(dataToSave);
                }
                if (response.success) {
                    this.$newSnackbarSuccess(response.message)
                    this.$emit('closeModal')
                } else {
                    this.$newSnackbarError(response.message)
                }
            } catch (error) {
                this.$newSnackbarSuccess(error.message)
            }
        },
        async getAllPermissions() {
            let permissions = await PermissionsService.getAll()
            permissions.filter(e => e.isMenu).forEach(menu => {
                let temp = []
                permissions.filter(e => !e.isMenu).forEach(permission => {
                    if (permission.menuId == menu.id) {
                        temp.push({
                            text: permission.visualName,
                            value: permission.id,
                        })
                    }
                });
                this.permissionsList.push({
                    ...menu,
                    options: temp,
                    itemsSelected: []
                })
            })
        },
        async savePermissions() {
            let permissionsSelected = []
            this.permissionsList.forEach(permission => {
                permission.itemsSelected.forEach(element => {
                    permissionsSelected.push(element)
                });
            })
        },
        async mapPermissions() {
            let rolePermissions = await PermissionsService.getAllByRole(this.data.codigo);
            this.permissionsList = this.permissionsList.map(item => {
                let selected = []
                item.options.forEach(menu => {
                    if (rolePermissions.find(e => e.id == menu.value)) {
                        selected.push(menu.value);
                    }
                })
                item.itemsSelected = selected;
                return {
                    ...item
                }
            })
        },
        async getGroups() {
            this.chatGroupsList = await ChatService.getConversationsGroups();
        },
        mapGroups(groupsCodes) {
            if (!groupsCodes) {
                return []
            }
            return this.chatGroupsList.filter(e => {
                return e.codigo == groupsCodes.split(',').find(x => x == e.codigo)
            })
        }
    },
    watch: {

    },
};
</script>
<style lang="scss" scoped></style>