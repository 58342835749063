import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import './interceptor/axiosInterceptor.js';
require('moment/locale/es');

// import 'bootstrap/dist/css/bootstrap.css'
// import 'bootstrap-vue/dist/bootstrap-vue.css'
// import 'bootstrap-icons/font/bootstrap-icons.css'
import 'vue-select/dist/vue-select.css';
import { ROLES_CAN_SHOW_DESCRIPTION } from '@/shared/generalConstants';
import moment from 'moment-timezone';
import './app.scss'

moment.tz.setDefault('America/Bogota');

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

Vue.config.productionTip = false

store.dispatch('connectToChat')
// 
// if (store.getters.isLogged) {
//   Vue.prototype.socket = io('http://186.116.13.121:9004', { query: { id: store.state.userLogged.documento } }); // Cambia la URL por la de tu servidor
//   Vue.prototype.socket.on('front-chat-message', msg => {
//     store.dispatch('onReceivedChatMessage', msg)
//   });
//   Vue.prototype.socket.on("error", (error) => {
//     // Captura y maneja errores de conexión
//     store.state.socket.isConnected = false;
//     console.error("Error de conexión con el servidor:", error);
//   });
//   Vue.prototype.socket.on("connect", () => {
//     // Captura y maneja errores de conexión
//     store.state.socket.isConnected = true;
//     console.error("Conexion exitosa:");
//   });
// }




Vue.prototype.$newSnackbarError = (message) => {
  store.state.snackbarData = {
    error: true,
    variant: 'danger',
    message: message
  }
}

Vue.prototype.$newSnackbarWarning = (message) => {
  store.state.snackbarData = {
    error: true,
    variant: 'warning',
    message: message
  }
}

Vue.prototype.$newSnackbarSuccess = (message) => {
  store.state.snackbarData = {
    error: false,
    variant: 'success',
    message: message
  }
}

Vue.prototype.checkPermission = (permissionName) => {
  return store.getters.userCan(permissionName);
}

Vue.prototype.canShowDescription = () => {
  return ROLES_CAN_SHOW_DESCRIPTION.indexOf(store.state.userLogged.role) >= 0 ? false : true
}



new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
