export const API_CONSTANTS = {
    BASE_URL: "https://api-repaldia-AEViPD3hXS3X.alianzadx.com/api",
    // BASE_URL: "http://localhost:9003/api",
    ENDPOINTS: {
        AUTH: {
            LOGIN: "/auth/login",
            REGISTER: "/auth/registre",
            LOGOUT: "/auth/logout"
        },
        GET_USER_LOGGED: "/user-logged",
        NOVEDADES: {
            DETAILS_NOVELTY: "/novedades",
            LISTAR_NOVEDADES_PUBLICADAS: "/novedades/listar-publicadas",
            GUARDAR_NOVEDAD: "/novedades/guardar",
            GUARDAR_Y_PUBLICAR_NOVEDAD: "/novedades/guardar-publicar",
            ACTUALIZAR_NOVEDAD: "/novedades/actualizar"
        },
        MAESTROS: {
            SERVICES: "/maestros/servicios",
            MEDICOS: "/maestros/medicos",
            PROGRAMS_BY_SERVICE: "/maestros/programas/by-service",
            NOVELTY_TYPE: "/maestros/tipo-novedades",
            CAUSES: "/maestros/causas",
            ROLES: "/maestros/roles",
            PERMISSIONS: "/maestros/permisos",
            PERMISSIONS_BY_ROLE: "/maestros/permisos/by-role",
            PERMISSIONS_MENUS: "/maestros/permisos/menus",
            CUSTOMERS: "/maestros/clientes",
            CAMPUS: "/maestros/sedes",
            USERS: "maestros/usuarios"
        },
        CHATS: {
            CONVERSATIONS: "/chat/conversaciones",
            MESSAGES: "/chat/mensajes",
            CONVERSATIONS_GROUPS: "/chat/grupos",
        }
    },

}

export const ROLES_CAN_SHOW_DESCRIPTION = [1,2]