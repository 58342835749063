import axios from 'axios';
import Vue from 'vue'
import { API_CONSTANTS } from "@/shared/generalConstants";
import Auth from '@/views/Auth/services/Auth';
import router from '../router'
import store from '../store'

// Configura la URL base si es necesario
axios.defaults.baseURL = API_CONSTANTS.BASE_URL;

// Agrega el interceptor de solicitud
axios.interceptors.request.use(async request => {
  const userLogged = JSON.parse(localStorage.getItem('userLogged'));
  if (userLogged && userLogged.token) {
    request.headers = {
      ...request.headers,
      Authorization: 'Bearer ' + userLogged.token
    }
  }
  return request;
});

axios.interceptors.response.use(response => {
  return response;
},
  error => {
    console.log('Interceptor error:', error)
    if (error.response.status === 401) {
      Auth.logout();
      store.state.snackbarData = {
        error: true,
        variant: 'warning',
        message: 'Tu sesión ha caducado. Por favor inicia sesión de nuevo.'
      }
      router.replace('/login')
    }
    if (error.response.status === 500) {
      store.state.snackbarData = {
        error: true,
        variant: 'danger',
        message: 'Ha ocurrido un error.'
      }
      router.replace('/login')
    }
    return Promise.reject(error);
  }
);

// Agrega Axios a la instancia de Vue para usarlo en todos los componentes
Vue.prototype.$axios = axios;
