<template>
    <div style="font-size: .8rem;">
        <b-row class="mb-3">
            <b-col>
                <span class="card-title">Biblioteca de Cups</span>
            </b-col>
            <b-col class="text-right">
                <!-- <b-button variant="success" class="ml-auto" to="/biblioteca/crear"
                    v-if="checkPermission('library-admin')"><b-icon icon="plus-circle" style="cursor: pointer;"></b-icon>
                    Nuevo</b-button> -->
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-form-group label="Buscar por:" v-slot="{ ariaDescribedby }">
                    <b-form-radio-group v-model="queryOptionSelected" :options="queryOptions"
                        :aria-describedby="ariaDescribedby" name="radio-options"></b-form-radio-group>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-input-group size="lg" class="mb-2">
                    <b-input-group-prepend is-text>
                        <b-icon icon="search"></b-icon>
                    </b-input-group-prepend>
                    <b-form-input type="search" v-model="textSearched" placeholder="Escriba para buscar..."></b-form-input>
                </b-input-group>
            </b-col>
        </b-row>
        <b-row class="mb-3">
            <b-col>
                Resultados encontrados: {{ arrayCupsFiltered.length }}
            </b-col>
        </b-row>
        <b-row class="letra">
            <b-col>
                <b-card no-body class="mb-1" v-for="(item, index) in arrayCupsFiltered" :key="item.cups"
                    id="item['CÓDIGO CUPS']">
                    <b-card-header header-tag="header" class="p-1" role="tab"
                        style="background-color: #2A2F43; color: white; font-size: .8rem;">
                        <p v-b-toggle="`accordion-${index + 1}`" class="p-0 m-2">{{
                            item['CÓDIGO CUPS'] }} - {{ item['NOMBRE DE PROCEDIMIENTO'] }}</p>
                        <!-- <b-button size="sm" block v-b-toggle="`accordion-${index + 1}`" class="text-left"
                                            style="border:none !important; opacity: 0.5;"></b-button> -->
                    </b-card-header>
                    <b-collapse :id="`accordion-${index + 1}`" :visible="showOpenTab()" accordion="my-accordion"
                        role="tabpanel">
                        <b-card-body>
                            <table class="table table-striped table-sm ">
                                <tbody>
                                    <tr v-for="column in Object.keys(item)" :key="column">
                                        <th style="width: 16rem;">{{ column }}:</th>
                                        <td class="pl-2 text-justify">{{ item[column] }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </b-card-body>
                    </b-collapse>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>
  
<script>
import informacion from '../../cups.json'
export default {

    data: () => ({
        queryOptionSelected: 'CÓDIGO CUPS',
        queryOptions: [
            { text: 'Código Cups', value: 'CÓDIGO CUPS' },
            { text: 'Nombre Procedimiento', value: 'NOMBRE DE PROCEDIMIENTO' }
        ],
        arrayCups: [],
        arrayCabeceras: [],
        arrayCupsFiltered: [],
        textSearched: ''
    }),
    mounted() {
        this.arrayCups = informacion
        this.search()
    },
    methods: {
        search(value) {
            if (value == '') {
                this.arrayCupsFiltered = []
                return
            }

            let _this = this;
            var resultados = this.arrayCups.filter(function (cup) {
                var regex = new RegExp(value, "i"); // Expresión regular, "i" para hacerlo case-insensitive
                return regex.test(cup[_this.queryOptionSelected]);
            });
            this.arrayCupsFiltered = resultados
        },
        showOpenTab() {
            return this.arrayCupsFiltered.length == 1
        },
        onOpenTab() {
            this.scrollToCenter()
        },
        scrollToCenter() {
            window.scrollTo({
                top: document.body.scrollHeight,
                behavior: 'smooth'
            });
            // var element = document.getElementById(elementId);

            // if (element) {
            //     var elementRect = element.getBoundingClientRect();
            //     var elementOffsetTop = elementRect.top + window;
            //     var windowHeight = window.innerHeight;
            //     var scrollPosition = elementOffsetTop - (windowHeight / 2);

            //     window.scrollTo({
            //         top: scrollPosition,
            //         behavior: 'smooth'
            //     });
            // }
        }
    },
    watch: {
        textSearched(newVal) {
            this.search(newVal)
        },
        queryOptionSelected() {
            this.arrayCupsFiltered = []
            this.search(this.textSearched)

        }
    },
};
</script>
  
<style lang="scss" scoped>
// .letra {
//     font-size: .8rem !important
// }
</style>