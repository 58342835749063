import axios from 'axios';
import { API_CONSTANTS } from '@/shared/generalConstants';

async function getAll() {
    let response = await axios.get(API_CONSTANTS.ENDPOINTS.MAESTROS.CAUSES)
    return response.data
}

async function getAllActive() {
    let response = await axios.get(API_CONSTANTS.ENDPOINTS.MAESTROS.CAUSES + '/activas')
    return response.data
}

async function getCause(id) {
    let response = await axios.get(`${API_CONSTANTS.ENDPOINTS.MAESTROS.CAUSES}/${id}`)
    return response.data
}

async function saveCause(data) {
    return new Promise(async (resolve, reject) => {
        try {
            let response = await axios.post(API_CONSTANTS.ENDPOINTS.MAESTROS.CAUSES, data)
            resolve(response.data)
        } catch (error) {
            reject(error)
        }
    })
}

async function updateCause(data, id) {
    return new Promise(async (resolve, reject) => {
        try {
            let response = await axios.put(`${API_CONSTANTS.ENDPOINTS.MAESTROS.CAUSES}/${id}`, data)
            resolve(response.data)
        } catch (error) {
            reject(error)
        }
    })
}

async function deleteCause(id) {
    return new Promise(async (resolve, reject) => {
        try {
            let response = await axios.delete(`${API_CONSTANTS.ENDPOINTS.MAESTROS.CAUSES}/${id}`)
            resolve(response.data)
        } catch (error) {
            reject(error)
        }
    })
}

export default {
    getAll,
    getAllActive,
    getCause,
    saveCause,
    updateCause,
    deleteCause
}