import axios from 'axios';
import { API_CONSTANTS } from '@/shared/generalConstants';

async function getAll() {
    let response = await axios.get(API_CONSTANTS.ENDPOINTS.MAESTROS.PERMISSIONS)
    return response.data
}

async function getAllByRole(roleId) {
    let response = await axios.get(API_CONSTANTS.ENDPOINTS.MAESTROS.PERMISSIONS_BY_ROLE, {
        params: {
            role: roleId
        }
    })
    return response.data
}

export default {
    getAll,
    getAllByRole
}