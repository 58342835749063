<template>
    <b-card style="border: none; padding: 5px 25px;">
        <b-row class="mb-3">
            <b-col class="p-0">
                <span class="card-title">Administración - Usuarios</span>
            </b-col>
            <!-- <b-col class="text-right">
                <b-button variant="outline-info" class="ml-auto" v-if="checkPermission('users-admin')">
                    <b-icon icon="plus-circle" style="cursor: pointer;"></b-icon> Nuevo
                </b-button>
            </b-col> -->
        </b-row>
        <b-row class="mb-2">
            <b-col cols="12">
                <label for="" class="mr-4"><b>Filtros:</b></label>
                <b-form-radio v-model="filterType" value="nombre" inline>Nombre</b-form-radio>
                <b-form-radio v-model="filterType" value="cargo" inline>Cargo</b-form-radio>
                <b-form-radio v-model="filterType" value="role" inline>Role</b-form-radio>

            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12">
                <b-input-group size="sm" class="mb-2">
                    <b-input-group-prepend is-text>
                        <b-icon icon="search"></b-icon>
                    </b-input-group-prepend>
                    <b-form-input type="search" placeholder="Buscar" v-model="searchText"></b-form-input>
                </b-input-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="mb-3">
                <b-table fixed bordered small :items="itemsFiltered" :fields="fields" responsive head-variant="dark"
                    :per-page="perPage" :current-page="currentPage" class="custom-table">

                </b-table>
                <div class="overflow-auto">
                    <b-pagination v-model="currentPage" :total-rows="items.length" :per-page="perPage"></b-pagination>
                </div>
            </b-col>
        </b-row>
    </b-card>
</template> 
  
<script>
import UsersService from '@/services/UsersService';


export default {
    components: {
        // DetailsNovelty
    },
    data: () => ({
        items: [],
        fields: ['nombre', 'cargo', 'role'],
        perPage: 10,
        currentPage: 1,
        itemsFiltered: [],
        filterType: '',
        searchText: ''
    }),
    mounted() {
        this.getUsers()
    },
    methods: {
        async getUsers() {
            this.items = (await UsersService.getAll()).map(e => {
                return {
                    ...e,
                    cargo: e.cargo.toUpperCase(),
                    role: e.role.toUpperCase()
                }
            });
            this.itemsFiltered = this.items
        }
    },
    watch: {
        searchText(newVal) {
            switch (this.filterType) {
                case 'nombre':
                    this.itemsFiltered = this.items.filter(e => e.nombre.includes(newVal.toUpperCase()))
                    break;
                case 'cargo':
                    this.itemsFiltered = this.items.filter(e => e.cargo.includes(newVal.toUpperCase()))
                    break;
                case 'role':
                    this.itemsFiltered = this.items.filter(e => e.role.includes(newVal.toUpperCase()))
                    break
                default:
                    this.itemsFiltered = this.items
                    break;
            }

        }
    },
};
</script>
  
<style lang="scss" scoped></style>