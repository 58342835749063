<template>
    <div>
        <b-row class="mb-3">
            <b-col>
                <span class="card-title">Causas</span>
            </b-col>
            <b-col class="text-right">
                <b-button variant="success" class="ml-auto" v-if="checkPermission('causes-admin')" @click="showSave()">
                    <b-icon icon="plus-circle" style="cursor: pointer;"></b-icon> Nuevo
                </b-button>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="mb-3">
                <b-table fixed bordered small :items="items" :fields="fields" responsive head-variant="dark"
                    class="custom-table">
                    <template #cell(acciones)="data">
                        <b-icon class="mr-2" @click="showSave(data.item)"
                            :icon="checkPermission('causes-admin') ? 'pencil-square' : 'eye'" font-scale="1.5"
                            style="cursor: pointer;"></b-icon>
                        <b-icon variant="danger" class="ml-2" @click="deleteItem(data.item)" icon="trash" font-scale="1.5"
                            style="cursor: pointer;" v-if="checkPermission('causes-admin')"></b-icon>
                    </template>
                    <template #cell(nombre)="data">
                        <div :title="data.item.descripcion">{{ data.item.nombre }}</div>
                    </template>
                    <template #cell(activo)="data">
                        <b-form-checkbox disabled switch v-model="data.item.activo" value="1"></b-form-checkbox>
                    </template>
                    <template #cell(showOtherCause)="data">
                        <b-form-checkbox disabled switch v-model="data.item.showOtherCause" value="1"></b-form-checkbox>
                    </template>
                </b-table>
            </b-col>
        </b-row>
        <b-modal id="modal-save-cause" size="md" hide-header hide-footer>
            <SaveCause @closeModal="closeModal()" :data="causeSelected"></SaveCause>
        </b-modal>
    </div>
</template> 
  
<script>
import { showMessageBox } from '../../shared/utils'
import CausesService from '../../services/CausesService'
import SaveCause from './Crear.vue'
require('moment/locale/es');

export default {
    components: {
        SaveCause
    },
    data: () => ({
        items: [],
        fields: [
            'nombre',
            { key: 'activo', label: 'Activo', thStyle: { width: "10%" } },
            { key: 'showOtherCause', label: 'Requiere Otra Causa', thStyle: { width: "10%" } },
            { key: 'acciones', label: '', thStyle: { width: "15%" } }
        ],
        causeSelected: null
    }),
    mounted() {
        this.getCauses()
    },
    methods: {
        async getCauses() {
            this.items = await CausesService.getAll();
        },
        async showSave(cause) {
            if (cause) {
                let response = await CausesService.getCause(cause.codigo)
                this.causeSelected = response;
            } else {
                this.causeSelected = null;
            }
            this.$bvModal.show('modal-save-cause')
        },
        async deleteItem(cause) {
            let rta = await showMessageBox(this, 'Confirmar eliminación', 'Confirma que desea eliminar este item?');
            if (rta) {
                CausesService.deleteCause(cause.codigo).then(rta => {
                    this.$newSnackbarSuccess(rta.message)
                    this.getCauses()
                }).catch(error => {
                    this.$newSnackbarError(error.message)
                })
            }
        },
        closeModal() {
            this.$bvModal.hide('modal-save-cause')
            this.getCauses()
        }
    },
    watch: {

    },
};
</script>
  
<style lang="scss" scoped>
.link-categorias {
    text-decoration: underline !important;
    color: purple;
    margin-right: 1rem;
}

.fecha-novedad {
    font-size: 12px;
    color: #F25743;
    padding: 0px;
    margin: 2px;
}

.title-novedad {
    color: #2a2f43;
    cursor: pointer;
    font-weight: bold;
    font-stretch: semi-condensed;
}

.texto-novedad {
    text-align: justify;
    color: #7f828f
}

.warning-title {
    font-size: 14px;
    font-weight: 700;
}

.warning-title-2 {
    font-size: 25px;
    font-weight: 600;
}

a {
    text-decoration: none !important;
    color: #7f828f;
    cursor: pointer;
}
</style>