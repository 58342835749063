import axios from 'axios';
import { API_CONSTANTS } from '@/shared/generalConstants';

async function getNoveltyTypes() {
    let response = await axios.get(API_CONSTANTS.ENDPOINTS.MAESTROS.NOVELTY_TYPE)
    return response.data
}

async function getNoveltyTypesActive() {
    let response = await axios.get(API_CONSTANTS.ENDPOINTS.MAESTROS.NOVELTY_TYPE + '/activas')
    return response.data
}

async function getNoveltyType(id) {
    let response = await axios.get(`${API_CONSTANTS.ENDPOINTS.MAESTROS.NOVELTY_TYPE}/${id}`)
    return response.data
}

async function saveNoveltyType(data) {
    return new Promise(async (resolve, reject) => {
        try {
            let response = await axios.post(API_CONSTANTS.ENDPOINTS.MAESTROS.NOVELTY_TYPE, data)
            resolve(response.data)
        } catch (error) {
            reject(error)
        }
    })
}

async function updateNoveltyType(data, id) {
    return new Promise(async (resolve, reject) => {
        try {
            let response = await axios.put(`${API_CONSTANTS.ENDPOINTS.MAESTROS.NOVELTY_TYPE}/${id}`, data)
            resolve(response.data)
        } catch (error) {
            reject(error)
        }
    })
}

async function deleteNoveltyType(id) {
    return new Promise(async (resolve, reject) => {
        try {
            let response = await axios.delete(`${API_CONSTANTS.ENDPOINTS.MAESTROS.NOVELTY_TYPE}/${id}`)
            resolve(response.data)
        } catch (error) {
            reject(error)
        }
    })
}

export default {
    getNoveltyTypes,
    getNoveltyTypesActive,
    getNoveltyType,
    saveNoveltyType,
    updateNoveltyType,
    deleteNoveltyType
}