<template>
    <b-card style="border: none; padding: 5px 25px;">
        <b-row class="mb-3">
            <b-col class="p-0">
                <span class="card-title">Información de Cup</span>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-form-group label="Título">
                    <b-form-input v-model="form.titulo"></b-form-input>
                </b-form-group>
                <b-form-group label="Descripción">
                    <b-form-textarea v-model="form.descripcion" rows="6"></b-form-textarea>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="text-right">
                <b-button variant="secondary" class="general-button ml-auto" size="sm"
                    @click="guardarNovedad()">Guardar</b-button>
            </b-col>
        </b-row>
    </b-card>
</template>
  
<script>
// import { API_CONSTANTS } from '@/shared/generalConstants';
// import axios from 'axios';


export default {
    data: () => ({
        form: {
            titulo: '',
            descripcion: ''
        }
    }),
    mounted() {
    },
    methods: {
        guardarNovedad() {
            // axios.post(API_CONSTANTS.ENDPOINTS.NOVEDADES.GUARDAR_NOVEDAD, {
            //     ...this.form,
            //     publicar: publicar
            // }).then(rta => {
            //     if (rta.data.success === true) {
            //         this.$router.replace('/novedades')
            //     }
            // })
        },
    },
    watch: {

    },
};
</script>
  
<style lang="scss" scoped>
.card-title {
    color: #9b9da5;
    font-size: 24px;
    font-weight: bold;
}



.fecha-novedad {
    font-size: 12px;
    color: #F25743;
    padding: 0px;
    margin: 2px;
}

.title-novedad {
    color: #2a2f43;
    cursor: pointer;
    font-weight: bold;
    font-stretch: semi-condensed;
}

.texto-novedad {
    text-align: justify;
    color: #7f828f
}

.warning-title {
    font-size: 14px;
    font-weight: 700;
}

.warning-title-2 {
    font-size: 25px;
    font-weight: 600;
}

a {
    text-decoration: none !important;
    color: #7f828f;
    cursor: pointer;
}
</style>