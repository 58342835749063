import axios from 'axios';
import { API_CONSTANTS } from '@/shared/generalConstants';

async function getAll() {
    let response = await axios.get(API_CONSTANTS.ENDPOINTS.MAESTROS.CAMPUS)
    return response.data
}

async function getAllActive() {
    let response = await axios.get(API_CONSTANTS.ENDPOINTS.MAESTROS.CAMPUS + '/activas')
    return response.data
}


export default {
    getAll,
    getAllActive
}