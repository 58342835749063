import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from "@/views/Auth/LoginView";
import Register from "@/views/Auth/RegisterView";
import store from '@/store'
import moment from 'moment';
import IndexBiblioteca from '@/views/Biblioteca/Index.vue'
import CrearBiblioteca from '@/views/Biblioteca/Crear.vue'
import IndexNovedades from '@/views/Novedades/Index.vue'
import CrearNovedades from '@/views/Novedades/Crear.vue'
import IndexTipoNovedades from '@/views/TipoNovedades/Index.vue'
import IndexCausas from '@/views/Causas/Index.vue'
import IndexRoles from '@/views/Roles/Index.vue'
import IndexUsuarios from '@/views/Usuarios/Index.vue'
import ChatIndex from '@/views/Chat/Index.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/novedades'
  },
  {
    path: '/chat',
    component: ChatIndex,
    meta: { requireAuth: true },
  },
  {
    path: '/biblioteca',
    component: IndexBiblioteca,
    meta: { requireAuth: true },
  },
  {
    path: '/biblioteca/crear',
    component: CrearBiblioteca,
    meta: { requireAuth: true },
  },
  {
    path: '/novedades',
    component: IndexNovedades,
    meta: { requireAuth: true },
  },
  {
    path: '/causas',
    component: IndexCausas,
    meta: { requireAuth: true },
  },
  {
    path: '/tipo-novedades',
    component: IndexTipoNovedades,
    meta: { requireAuth: true },
  },
  {
    path: '/novedades/crear',
    component: CrearNovedades,
    meta: { requireAuth: true }
  },
  {
    path: '/novedades/editar',
    component: CrearNovedades,
    meta: { requireAuth: true }
  },
  {
    path: '/novedades/nueva',
    component: CrearBiblioteca,
    meta: { requireAuth: true },
  },
  {
    path: '/roles',
    component: IndexRoles,
    meta: { requireAuth: true },
  },
  {
    path: '/usuarios',
    component: IndexUsuarios,
    meta: { requireAuth: true },
  },
  {
    path: "/login",
    component: Login
  },
  {
    path: "/register",
    component: Register
  },
]
// esto es una prueba
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// Verificando si hay token en el estado
// Este método realiza una logica al cambiar de rutas
router.beforeEach(async (to, from, next) => {
  // Usamos to para verificar si requiera autenticación
  const protectedRoute = to.matched.some(record => record.meta.requireAuth)
  // Procedemos a verificar el token
  // console.log(store.state.token)
  // if (protectedRoute && (store.state.token === null || store.state.token === undefined || store.state.token === '')) {
  //   next({path: '/login'})
  // } else {
  //   console.log('siguiente')
  //   next()
  // }
  let userLogged = store.getters.getUserLogged ?? null
  let timeElapsed = userLogged ? (moment() - userLogged.dateLogged) : 0;

  if (timeElapsed > 28800000) {
    store.dispatch('logout')
    next({ path: '/login' })
  }

  if (protectedRoute && !store.getters.isLogged) {   //28800000 son 8 horas de inactividad y se cierra la sesion
    next({ path: '/login' })
  } else {
    next()
  }


})

export default router
