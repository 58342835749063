<template>
    <div>
        <b-row class="mb-1">
            <b-col>
                <span class="card-title">{{ isEditing ? 'Modificar' : 'Crear' }} Tipo de Novedad</span>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="6">
                <b-form-group label="Activo:">
                    <b-form-checkbox :disabled="!checkPermission('type-novelties-admin')" v-model="form.activo"
                        switch></b-form-checkbox>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12">
                <b-form-group label="Nombre:">
                    <b-form-input :disabled="!checkPermission('type-novelties-admin')" v-model="form.nombre"></b-form-input>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12">
                <b-form-group label="Descripción:">
                    <b-form-textarea :disabled="!checkPermission('type-novelties-admin')" v-model="form.descripcion"
                        rows="6"></b-form-textarea>
                </b-form-group>
            </b-col>
        </b-row>

        <b-row>
            <b-col cols="4">
                <b-form-group label="Requiere Cliente:">
                    <b-form-checkbox :disabled="!checkPermission('type-novelties-admin')" v-model="form.requiereCliente"
                        switch></b-form-checkbox>
                </b-form-group>
            </b-col>
            <!-- </b-row>
        <b-row> -->
            <b-col cols="4">
                <b-form-group label="Requiere Causa:">
                    <b-form-checkbox :disabled="!checkPermission('type-novelties-admin')" v-model="form.requiereCausa"
                        switch></b-form-checkbox>
                </b-form-group>
            </b-col>
            <!-- </b-row>
        <b-row> -->
            <b-col cols="4">
                <b-form-group label="Requiere Médico:">
                    <b-form-checkbox :disabled="!checkPermission('type-novelties-admin')" v-model="form.requiereMedico"
                        switch></b-form-checkbox>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="text-right">
                <b-button variant="secondary" class="general-button ml-auto" size="sm" @click="saveItem()"
                    :disabled="!activeSave" v-if="checkPermission('type-novelties-admin')">Guardar</b-button>
            </b-col>
        </b-row>
    </div>
</template>
  
<script>
import NoveltyTypesService from '@/services/NoveltyTypesService';

export default {
    props: {
        data: null
    },
    components: {

    },
    data: () => ({
        form: {
            nombre: '',
            descripcion: '',
            requiereCausa: false,
            requiereCliente: false,
            requiereMedico: false,
            activo: true
        },
        isEditing: false
    }),
    async mounted() {
        if (this.data) {
            this.isEditing = true
            this.form = {
                nombre: this.data.nombre,
                descripcion: this.data.descripcion,
                requiereCausa: !!this.data.requiereCausa,
                requiereCliente: !!this.data.requiereCliente,
                requiereMedico: !!this.data.requiereMedico,
                activo: !!this.data.activo
            }
        }
    },
    computed: {
        activeSave() {
            if (!this.form.nombre) {
                return false;
            }
            return true
        }
    },
    methods: {
        async saveItem() {
            try {
                let dataToSave = this.form
                let response = null;
                if (this.isEditing) {
                    response = await NoveltyTypesService.updateNoveltyType(dataToSave, this.data.codigo);
                } else {
                    response = await NoveltyTypesService.saveNoveltyType(dataToSave);
                }
                if (response.success) {
                    this.$newSnackbarSuccess(response.message)
                    this.$emit('closeModal')
                } else {
                    this.$newSnackbarError(response.message)
                }
            } catch (error) {
                this.$newSnackbarSuccess(error.message)
            }
        },
    },
    watch: {

    },
};
</script>
<style lang="scss" scoped></style>