<template>
    <div>
        <b-overlay :show="loadingData" rounded="sm">
            <b-row class="mb-3">
                <b-col>
                    <span class="card-title">{{ isEditing ? 'Modificar' : 'Crear' }} Novedad</span>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" md="6">
                    <b-form-group label="Tipo">
                        <multiselect v-model="form.tipoNovedad" :options="listadoTiposNovedad" track-by="codigo" searchable
                            label="nombre" placeholder="Seleccione...">
                        </multiselect>
                    </b-form-group>
                </b-col>
                <b-col cols="12" md="6" v-if="(form.tipoNovedad && form.tipoNovedad.requiereCliente) ?? false">
                    <b-form-group label="Cliente">
                        <multiselect v-model="form.cliente" :options="listadoClientes" track-by="codigo" searchable
                            label="nombre" placeholder="Seleccione...">
                        </multiselect>
                    </b-form-group>
                </b-col>
                <!-- </b-row>
            <b-row> -->
                <b-col cols="12" md="6">
                    <b-form-group label="Servicio">
                        <multiselect v-model="form.servicio" :options="listadoServicios" track-by="codigo" searchable
                            label="nombre" placeholder="Seleccione...">
                        </multiselect>
                    </b-form-group>
                </b-col>
                <b-col cols="12" md="6" v-if="(form.tipoNovedad && form.tipoNovedad.requiereMedico) ?? false">
                    <b-form-group label="Médico">
                        <multiselect v-model="form.medico" :options="listadoMedicos" track-by="codigo" searchable label="nombre"
                            placeholder="Seleccione...">
                        </multiselect>
                    </b-form-group>
                </b-col>
                <!-- </b-row>
            <b-row> -->
                <b-col cols="12" md="6" v-if="(form.tipoNovedad && form.tipoNovedad.requiereCausa) ?? false">
                    <b-form-group label="Causa">
                        <multiselect v-model="form.causa" :options="listadoCausas" track-by="codigo" searchable label="nombre"
                            placeholder="Seleccione...">
                        </multiselect>
                    </b-form-group>
                </b-col>
                <b-col cols="12" md="6">
                    <b-form-group label="Descripción de la causa" v-if="(form.causa && form.causa.showOtherCause) ?? false">
                        <b-form-input v-model="form.otraCausa"></b-form-input>
                    </b-form-group>
                </b-col>
                <!-- </b-row>
            <b-row> -->
                <b-col cols="12">
                    <b-form-group label="Sede">
                        <multiselect size="sm" v-model="form.sedes" :options="listadoSedes" multiple :show-labels="true"
                            track-by="codigo" searchable :close-on-select="true" :clear-on-select="false" label="nombre"
                            group-values="items" group-label="option" :group-select="true"
                            placeholder="Seleccione una o varias sedes">
                        </multiselect>
                    </b-form-group>
                </b-col>
                <!-- </b-row>
            <b-row> -->
                <b-col cols="12" md="6">
                    <b-form-group label="Inicio publicación">
                        <b-form-datepicker v-model="form.fechaIni" class="mb-2" :min="minDate" locale="es"
                            required></b-form-datepicker>
                    </b-form-group>
                </b-col>
                <b-col cols="12" md="6">
                    <b-form-group label="Fin publicación">
                        <b-form-datepicker v-model="form.fechaFin" class="mb-2" :min="form.fechaIni ? form.fechaIni : minDate"
                            locale="es" required></b-form-datepicker>
                    </b-form-group>
                </b-col>
            </b-row>

            <b-row v-if="canShowDescription()">
                <b-col cols="12">
                    <b-form-group label="Descripción">
                        <b-form-textarea v-model="form.descripcion" rows="6"></b-form-textarea>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12">
                    <b-form-group label="Manejo">
                        <b-form-textarea v-model="form.manejo" rows="6"></b-form-textarea>
                    </b-form-group>
                </b-col>
            </b-row>

            <b-row>
                <b-col class="text-right">
                    <b-button variant="danger" class="ml-auto mr-3" @click="$router.replace('/novedades')"
                        >Cancelar</b-button>
                    <b-button variant="success" class="ml-auto" @click="saveNovelty()"
                        :disabled="!activeSave">Guardar</b-button>
                </b-col>
            </b-row>
            <template #overlay>
                <div class="text-center">
                <b-icon icon="stopwatch" font-scale="3" animation="cylon"></b-icon>
                <p id="cancel-label">Cargando datos...</p>
                </div>
            </template>
        </b-overlay>
    </div>
</template>
  
<script>
import Multiselect from 'vue-multiselect'
import ServicesService from '@/services/ServicesService'
import DoctorsService from '@/services/DoctorsService'
import CausesService from '@/services/CausesService'
import CustomersService from '@/services/CustomersService'
import NoveltyTypesService from '@/services/NoveltyTypesService'
import NoveltiesService from '@/services/NoveltiesService'
import CampusService from '@/services/CampusService'



export default {
    components: {
        Multiselect
    },
    data: () => ({
        form: {
            titulo: '',
            descripcion: '',
            fechaIni: null,
            fechaFin: null,
            manejo: '',
            servicio: null,
            medico: null,
            causa: null,
            tipoNovedad: null,
            otraCausa: '',
            cliente: null,
            sedes: []
        },
        isEditing: false,
        listadoServicios: [],
        listadoMedicos: [],
        listadoCausas: [],
        listadoClientes: [],
        listadoTiposNovedad: [],
        listadoSedes: [],
        minDate: new Date(),
        showCauses: false,
        showDoctors: false,
        showOtherCause: false,
        showCustomers: false,
        noveltyId: null,
        loadingData: false
    }),
    async mounted() {
        this.form = {
            titulo: '',
            descripcion: '',
            fechaIni: null,
            fechaFin: null,
            manejo: '',
            servicio: null,
            medico: null,
            causa: null,
            tipoNovedad: null,
            otraCausa: '',
            cliente: null,
            sedes: []
        }
        this.isEditing = false
        if (this.$route.path == '/novedades/editar') {
            this.isEditing = true;
        }
        this.loadingData = true;
        await Promise.all([
            this.listNoveltyTypes(),
            this.listServices(),
            this.listDoctors(),
            this.listCauses(),
            this.listCustomers(),
            this.listCampus()
        ])
        if(this.isEditing){
            this.noveltyId = this.noveltyToEdit.id;
            this.form = {
                // titulo: this.data.titulo,
                descripcion: this.noveltyToEdit.descripcion,
                fechaIni: this.noveltyToEdit.desde,
                fechaFin: this.noveltyToEdit.hasta,
                manejo: this.noveltyToEdit.manejo,
                servicio: this.listadoServicios.find(e => e.codigo == this.noveltyToEdit.codigoServicio),
                medico: this.listadoMedicos.find(e => e.codigo == this.noveltyToEdit.codigoMedico),
                causa: this.listadoCausas.find(e => e.codigo == this.noveltyToEdit.codigoCausa),
                tipoNovedad: this.listadoTiposNovedad.find(e => e.codigo == this.noveltyToEdit.codigoTipoNovedad),
                otraCausa: this.noveltyToEdit.otraCausa,
                cliente: this.listadoClientes.find(e => e.codigo == this.noveltyToEdit.cliente),
                sedes: this.noveltyToEdit.sedes
            }
            this.$store.dispatch('setNoveltyToEdit',null)
        }
        this.loadingData = false
        // await this.listNoveltyTypes()
        // await this.listServices()
        // await this.listDoctors()
        // await this.listCauses()
        // await this.listCustomers()
        // await this.listCampus()
        
        
    },
    computed: {
        activeSave() {
            if (!this.form.tipoNovedad) {
                return false;
            }
            if (!this.form.fechaIni) {
                return false;
            }
            if (!this.form.fechaFin) {
                return false;
            }
            if (this.canShowDescription() && !this.form.descripcion) {
                return false;
            }
            if (!this.form.manejo) {
                return false;
            }
            if (!this.form.servicio) {
                return false;
            }
            if (this.showCauses && !this.form.medico) {
                return false;
            }
            if (this.showCauses && !this.form.causa) {
                return false;
            }
            return true
        },
        noveltyToEdit(){
            return this.$store.state.noveltyToEdit;
        }
    },
    methods: {
        async saveNovelty() {
            try {
                let dataToSave = {
                    ...this.form,
                    titulo: this.form.tipoNovedad.nombre,
                    tipoNovedad: this.form.tipoNovedad.codigo,
                    cliente: this.form.cliente ? this.form.cliente.codigo : null,
                    nombreCliente: this.form.cliente ? this.form.cliente.nombre : null,
                    servicio: this.form.servicio.codigo,
                    nombreServicio: this.form.servicio.nombre,
                    medico: this.form.medico ? this.form.medico.codigo : null,
                    nombreMedico: this.form.medico ? this.form.medico.nombre : null,
                    causa: this.form.causa ? this.form.causa.codigo : null,
                    sedes: this.form.sedes
                }

                let response;
                if(this.isEditing){
                    response = await NoveltiesService.update(dataToSave,this.noveltyId);    
                }else{
                    response = await NoveltiesService.save(dataToSave);
                }
                if (response.success) {
                    this.$newSnackbarSuccess(response.message)
                    this.$store.dispatch('emitNewNoveltiesToSocket',dataToSave)
                    this.$router.replace('/novedades')
                } else {
                    this.$newSnackbarError(response.message)
                }
            } catch (error) {
                console.log('error', error)
                this.$newSnackbarSuccess(error.message)
            }
        },
        async listServices() {
            let data = await ServicesService.getAll()
            this.listadoServicios = data
        },
        async listDoctors() {
            let data = await DoctorsService.getAll()
            this.listadoMedicos = data
        },
        async listCauses() {
            let data = await CausesService.getAllActive()
            this.listadoCausas = data
        },
        async listNoveltyTypes() {
            let data = await NoveltyTypesService.getNoveltyTypesActive()
            this.listadoTiposNovedad = data
        },
        async listCustomers() {
            let data = await CustomersService.getAll()
            this.listadoClientes = data
        },
        async listCampus() {
            let rta = await CampusService.getAllActive()
            this.listadoSedes = [
                {
                    option: 'Todas',
                    items: rta
                }
            ]
        },

    },
    watch: {
        form: {
            deep: true,
            handler(newData){
                if(newData.tipoNovedad && newData.tipoNovedad.requiereCliente == 0){
                    newData.cliente = null
                }
                if(newData.tipoNovedad && newData.tipoNovedad.requiereMedico == 0){
                    newData.medico = null
                }
                if(newData.tipoNovedad && newData.tipoNovedad.requiereCausa == 0){
                    newData.causa = null
                }
                if(newData.causa && newData.causa.showOtherCause == 0){
                    newData.otraCausa = ''
                }
            }
        }
    },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped></style>