<template>
    <b-alert :show="dismissCountDown" dismissible fade :variant="variant" @dismiss-count-down="countDownChanged"
        @dismissed="dismissed()" class="position-fixed fixed-top rounded-0 w-300" style="z-index: 2000;">
        {{ text }}
    </b-alert>
</template>
<script>
export default {
    name: 'Snack-bar',
    props: {
        variant: String,
        text: String
    },
    data() {
        return {
            dismissSecs: 3,
            dismissCountDown: 0,
            showDismissibleAlert: false
        }
    },
    mounted() {
        this.showAlert()
    },
    methods: {
        countDownChanged(dismissCountDown) {
            this.dismissCountDown = dismissCountDown
        },
        showAlert() {
            this.dismissCountDown = this.dismissSecs
        },
        dismissed() {
            this.$store.state.snackbarData = {
                error: false,
                variant: '',
                message: ''
            }
        }
    }
}
</script>

<style scoped>
.w-300 {
    width: 300px;
    text-align: center;
    margin: 5rem auto;
}
</style>