<template>
    <b-card style="border: none; padding: 5px 25px;">
        <b-row class="mb-4">
            <b-col class="text-justify">
                <h3>{{ noveltyData.tipoNovedad }}</h3>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <p><b>Creado:</b> {{ noveltyData.fechaCreacion }}</p>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <p><b>Duración:</b> {{ noveltyData.desde }} - {{ noveltyData.hasta }}</p>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <p><b>Tipo:</b> {{ noveltyData.tipoNovedad }}</p>
                <p v-if="noveltyData.cliente"><b>Cliente:</b> {{ noveltyData.cliente }}</p>
                <p v-if="noveltyData.servicio"><b>Servicio:</b> {{ noveltyData.servicio }}</p>
                <p v-if="noveltyData.causa"><b>Causa:</b> {{ noveltyData.causa }}</p>
                <p v-if="noveltyData.medico"><b>Médico:</b> {{ noveltyData.medico }}</p>
                <p v-if="noveltyData.sedes.length > 0"><b>Sedes:</b> {{ noveltyData.sedes.map(e => e.nombre).join(', ') }}
                </p>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="text-justify">
                <label for=""><b>Manejo:</b></label>
                <b-form-textarea rows="7" v-model="noveltyData.manejo" disabled></b-form-textarea>
            </b-col>
        </b-row>
        <b-row v-if="canShowDescription()">
            <b-col class="text-justify">
                <label for=""><b>Descripción:</b></label>
                <b-form-textarea rows="7" v-model="noveltyData.descripcion" disabled></b-form-textarea>
            </b-col>
        </b-row>
    </b-card>
</template>
  
<script>
export default {
    props: {
        data: null
    },
    data: () => ({
        noveltyData: null
    }),
    async beforeMount() {
        this.noveltyData = this.data
    },
    methods: {

    },
    watch: {
    },
};
</script>
  
<style lang="scss" scoped>
.card-title {
    color: #9b9da5;
    font-size: 24px;
    font-weight: bold;
}



.fecha-novedad {
    font-size: 12px;
    color: #F25743;
    padding: 0px;
    margin: 2px;
}

.title-novedad {
    color: #2a2f43;
    cursor: pointer;
    font-weight: bold;
    font-stretch: semi-condensed;
}

.texto-novedad {
    text-align: justify;
    color: #7f828f
}

.warning-title {
    font-size: 14px;
    font-weight: 700;
}

.warning-title-2 {
    font-size: 25px;
    font-weight: 600;
}

a {
    text-decoration: none !important;
    color: #7f828f;
    cursor: pointer;
}
</style>