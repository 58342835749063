<template>
    <b-row>
        <b-col cols="12" sm="12" md="3">
            <div class="d-flex">
                <span class="card-subtitle">Filtrar listado:</span>
                <span class="ml-auto mr-2 mt-2" title="Limpiar filtros" style="cursor: pointer;" v-if="isFiltered" @click="cleanFilters()">
                    <b-icon icon="trash" variant="danger" scale="1.2"></b-icon>
                </span>
            </div>
            <b-form-group label="Tipo de Novedad">
                <multiselect v-model="filters.tipoNovedad" :options="listadoTiposNovedad" track-by="codigo" searchable
                    label="nombre" placeholder="Seleccione...">
                </multiselect>
            </b-form-group>
            <b-form-group label="Servicio">
                <multiselect v-model="filters.servicio" :options="listadoServicios" track-by="codigo" searchable
                    label="nombre" placeholder="Seleccione...">
                </multiselect>
            </b-form-group>
            <b-form-group label="Causa">
                <multiselect v-model="filters.causa" :options="listadoCausas" track-by="codigo" searchable label="nombre"
                    placeholder="Seleccione...">
                </multiselect>
            </b-form-group>
            <b-form-group label="Sede">
                <multiselect size="sm" v-model="filters.sedes" :options="listadoSedes" multiple :show-labels="true"
                    track-by="codigo" searchable :close-on-select="true" :clear-on-select="false" label="nombre"
                    group-values="items" group-label="option" :group-select="true"
                    placeholder="Seleccione una o varias sedes">
                </multiselect>
            </b-form-group>
        </b-col>
        <b-col cols="12" sm="12" md="9">
            <b-row class="mb-3">
                <b-col>
                    <span class="card-title">Novedades Recientes</span>
                </b-col>
            </b-row>
            <div v-if="novedades.length > 0">
                <b-row v-for="novedad in  novedades " :key="novedad.id" >
                    
                    <b-col class="mb-3">
                        <b-card class="card-novelty">
                            <b-card-text>
                                
                                <div class="d-flex">
                                    <h6 class="title-novedad" @click="showDetails(novedad.id)">{{novedad.tipoNovedad }}</h6>
                                    <p v-if="checkPermission('novelties-admin') && $store.getters.getUserLogged.documento == novedad.usuarioCrea" class="ml-auto"><b-icon icon="pencil-square" scale="1.8"  @click="editNovelty(novedad)" style="cursor: pointer;"></b-icon></p>
                                </div>
                                <p class="fecha-novedad text-right">{{ novedad.fechaCreacion }}</p>
                                <p class="texto-novedad" @click="showDetails(novedad.id)">{{ novedad.manejo }}</p>
                                <hr class="m-0 p-0">
                                <p v-if="novedad.medico" class="m-0">
                                    <b class="card-text">Médico: </b>
                                    <a title="Causa" class="link-categorias" href="">{{ novedad.nombreMedico }}</a>
                                </p>
                                
                                <p v-if="novedad.nombreServicio" class="m-0">
                                    <b class="card-text">Servicio: </b>
                                    <a title="Servicio" class="link-categorias" href="">{{ novedad.nombreServicio }}</a>
                                </p>
                                <p class="m-0" v-if="novedad.sedes.length > 0">
                                    <b class="card-text">Sedes: </b>
                                    <span class="p-0 m-0" v-for="sede in novedad.sedes" :key="sede.codigo"><a
                                            class="link-categorias" href="">{{
                                                sede.nombre }}</a></span>
                                    <!-- <div v-if="novedad.sedes.length > 1">
                                        <b-button id="popover-sedes">({{ novedad.sedes.length - 1
                                        }})
                                        </b-button>
                                        <b-popover target="popover-sedes" triggers="hover" placement="top">
                                            <template #title>Sedes</template>
                                            {{ novedad.sedes.map(e => e.nombre).join(', ') }}
                                        </b-popover>
                                        </div> -->
                                </p>
                            </b-card-text>
                        </b-card>
                    </b-col>
                </b-row>
            </div>
            <div v-else>
                <b-row>
                    <b-col class="text-center">
                        <span class="mt-5"><b>No se encontró información</b></span>
                    </b-col>
                </b-row>
            </div>
            <b-row v-if="novedades.length>perPage">
                <b-col>
                    <b-pagination
                    v-model="currentPage"
                    :total-rows="totalRows"
                    :per-page="perPage"
                    align="center"
                    @change="goToPage($event)"
                    ></b-pagination>
                </b-col>
            </b-row>
            <b-modal id="modal-details-novelty" size="lg" hide-header hide-footer>
                <DetailsNovelty :data="noveltySelected"></DetailsNovelty>
            </b-modal>
        </b-col>
    </b-row>
</template> 
  
<script>
import DetailsNovelty from './Details.vue'
import Multiselect from 'vue-multiselect'
import ServicesService from '@/services/ServicesService'
import CausesService from '@/services/CausesService'
import NoveltyTypesService from '@/services/NoveltyTypesService'
import NoveltiesService from '@/services/NoveltiesService'
import CampusService from '@/services/CampusService'
require('moment/locale/es');

export default {
    components: {
        DetailsNovelty,
        Multiselect
    },
    data: () => ({
        noveltySelected: null,
        listadoServicios: [],
        listadoCausas: [],
        listadoTiposNovedad: [],
        listadoSedes: [],
        filters: {
            servicio: null,
            causa: null,
            tipoNovedad: null,
            sedes: []
        },
        currentPage: 2,
        perPage: 10,
        totalRows: 20,
    }),
    mounted() {
        this.listNoveltyTypes()
        this.listServices();
        this.listCauses();
        this.listCampus();
        this.getNovedades()
    },
    computed: {
        novedades(){
            return this.$store.state.listNovelties
        },
        isFiltered(){
            return this.filters.servicio || this.filters.causa || this.filters.tipoNovedad || this.filters.sedes.length > 0
        }
    },
    methods: {
        async getNovedades(page) {
            await this.$store.dispatch('getAllNovelties',{
                page: page ?? 1,
                filters: {
                    servicio: this.filters.servicio ? this.filters.servicio.codigo : null,
                    causa: this.filters.causa ? this.filters.causa.codigo : null,
                    tipoNovedad: this.filters.tipoNovedad ? this.filters.tipoNovedad.codigo : null,
                    sedes: this.filters.sedes ? this.filters.sedes.map(e => e.codigo) : []
                }
            })
            this.currentPage = this.$store.state.pagination.currentPage
            this.perPage = this.$store.state.pagination.perPage
            this.totalRows = this.$store.state.pagination.totalRows
            // let novelties = await NoveltiesService.getAll();
            // this.totalNovedades = novelties.map(e => {
            //     let arraySedes = [];
            //     if (e.sedes) {
            //         arraySedes = JSON.parse(e.sedes)
            //     }
            //     return {
            //         ...e,
            //         sedes: arraySedes,
            //         fechaCreacion: moment(e.fechaCreacion).format('YYYY-MM-DD hh:mm a')
            //     }
            // })
            // this.principalesNovedades = this.totalNovedades
            // this.novedades = this.principalesNovedades;
        },
        async showDetails(noveltyId) {
            let details = await NoveltiesService.detailsNovelty(noveltyId);
            this.noveltySelected = details
            let arraySedes = [];
            if (details.sedes) {
                arraySedes = JSON.parse(details.sedes)
            }
            this.noveltySelected.sedes = arraySedes;
            this.$bvModal.show('modal-details-novelty')
        },
        editNovelty(noveltyData){
            this.$store.dispatch('setNoveltyToEdit',noveltyData)
            this.$router.push('/novedades/editar')
        },
        async listServices() {
            let data = await ServicesService.getAll()
            this.listadoServicios = data
        },
        async listCauses() {
            let data = await CausesService.getAllActive()
            this.listadoCausas = data
        },
        async listNoveltyTypes() {
            let data = await NoveltyTypesService.getNoveltyTypesActive()
            this.listadoTiposNovedad = data
        },
        async listCampus() {
            let rta = await CampusService.getAllActive()
            this.listadoSedes = [
                {
                    option: 'Todas',
                    items: rta
                }
            ]
        },
        goToPage(page){
            this.currentPage = page;
            this.getNovedades(page)
        },
        cleanFilters(){
            this.filters = {
                servicio: null,
                causa: null,
                tipoNovedad: null,
                sedes: []
            }
        }
    },
    watch: {
        filters: {
            deep: true,
            handler(){
                this.getNovedades(this.currentPage)
            }
        }
    },
};
</script>
  
<style lang="scss" scoped>
.link-categorias {
    text-decoration: underline !important;
    color: orangered;
    margin-right: 1rem;
    font-size: .7rem;
}

.card-body {
    background-color: white;
}

// .card-title {
//     color: #9b9da5;
//     font-size: 24px;
//     font-weight: bold;
// }

.card-text {
    color: #9b9da5;
    font-size: 12px;
    font-weight: bold;
}

.fecha-novedad {
    font-size: 0.8rem;
    color: orangered;
    padding: 0px;
    margin: 2px;
    font-style: italic;
}

.title-novedad {
    color: #2a2f43;
    cursor: pointer;
    font-weight: bold;
    font-stretch: semi-condensed;
    font-size: 1.2rem;
}

.texto-novedad {
    text-align: justify;
    color: #7f828f;
    cursor: pointer;
}

.card-novelty .card-body {
    box-shadow: 0px 3px 5px 1px #ccc !important;
    background-color: white;
    padding-left: 50px;
    padding-right: 50px;
}
</style>