import axios from 'axios';
import { API_CONSTANTS } from '@/shared/generalConstants';

async function getAllConversations() {
    let response = await axios.get(API_CONSTANTS.ENDPOINTS.CHATS.CONVERSATIONS)
    return response.data
}

async function getConversationMessages(conversationId) {
    let response = await axios.get(`${API_CONSTANTS.ENDPOINTS.CHATS.CONVERSATIONS}/${conversationId}`)
    return response.data
}

async function insertChatMessage(data) {
    let response = await axios.post(`${API_CONSTANTS.ENDPOINTS.CHATS.MESSAGES}`, data)
    return response.data
}

async function getConversationsGroups() {
    let response = await axios.get(`${API_CONSTANTS.ENDPOINTS.CHATS.CONVERSATIONS_GROUPS}`)
    return response.data
}

export default {
    getAllConversations,
    getConversationMessages,
    insertChatMessage,
    getConversationsGroups
}