import { render, staticRenderFns } from "./Crear.vue?vue&type=template&id=855292ba&scoped=true&"
import script from "./Crear.vue?vue&type=script&lang=js&"
export * from "./Crear.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "855292ba",
  null
  
)

export default component.exports