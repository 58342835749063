<template>
  <div id="app">
    <Snackbar v-if="$store.state.snackbarData.message" :text="$store.state.snackbarData.message"
      :variant="$store.state.snackbarData.variant"></Snackbar>
    <NavBar v-if="$store.getters.isLogged"></NavBar>
    <div v-if="$store.getters.isLogged" class="text-right mr-5">
      <p style="font-size: smaller; font-style: italic; font-weight: bold;">Usuario: {{
        `${$store.getters.getUserLogged
          ?
          $store.getters.getUserLogged.nombre : ''}` }}</p>
    </div>
    <div v-if="$store.getters.isLogged" id="navbar-mobile" class="text-center mb-2">
      <b-button-group>
        <b-button size="sm" to="/novedades" variant="primary" class="mr-1">
          Novedades
        </b-button>
        <b-button size="sm" to="/novedades/crear" variant="primary" class="mr-1">
          Crear Novedad
        </b-button>
        <b-button size="sm" to="/biblioteca" variant="primary" class="mr-1">
          Biblioteca
        </b-button>
      </b-button-group>
    </div>
    <b-container fluid>

      <b-row align-h="center">
        <b-col cols="12" sm="12">
          <b-card class="main-card">
            <router-view></router-view>
          </b-card>
        </b-col>
        <!-- <b-col cols="12" md="4" v-if="$route.path == '/novedades'">
            <b-card bg-variant="danger" text-variant="white" class="text-center">
              <b-card-text class="warning-title">Notificación Importante</b-card-text>
              <b-card-text class="warning-title-2">ALERTA!</b-card-text>
              <b-card-text style="font-size: 1.2rem;">Cancelación de Agenda del Doctor
                Ospina de 19/08/2023</b-card-text>
            </b-card>
          </b-col> -->
      </b-row>
    </b-container>
    <!-- </div> -->
  </div>
</template>

<script>
import Snackbar from "@/components/Snackbar.vue";
import NavBar from "@/components/Navbar.vue"


export default {
  components: {
    Snackbar,
    NavBar,
  },
  data() {
    return {
    }
  },
  created(){
    
  },
  methods: {
  }
}
</script>

<style lang="scss">
@import "node_modules/vue-bootstrap-sidebar/src/scss/default-theme";
@import './app.scss';

* {
  box-sizing: content-box;
  font-family: 'Open Sans', sans-serif !important;
  font-weight: 400;

}


body {
  background-color: #F4F7F9 !important;
  font-size: 13px;
}


.general-button {
  color: #9b9ead !important;
  box-shadow: 0 3px rgb(199, 204, 208);
  background-color: #e5eaee !important;
  border: none !important;
  font-weight: 500 !important;
}

.general-button:hover {
  background-color: $adx-primary !important;
  border: none !important;
  color: white !important;
}


.profile {
  display: none;
}


.card-title {
  color: $adx-primary;
  font-size: 1.5rem;
  font-weight: bold;
}

.card-subtitle {
  color: $adx-primary;
  font-size: 1.2rem;
  font-weight: bold;
}

.custom-table .thead-dark th {
  background-color: $adx-primary !important;
  /* Cambia el color de fondo del encabezado */
  color: #fff;
  border-color: #DFDFDF !important;
  /* Cambia el color del texto del encabezado */
}

.main-card {
  border: none;
  padding: 0px;
  margin: 0;
  box-shadow: 0px 3px 5px 1px #ccc !important;
  // height: 100vh;
  min-width: 700px;
}

.btn-primary {
  background-color: $adx-primary !important;
  border: none !important;
}

.bg-primary {
  background-color: $adx-primary !important;
}

.btn-success {
  background-color: $adx-success !important;
  border: none !important;
}

.text-info {
  color: $adx-info;
}

.multiselect__tag,
.multiselect__option--highlight {
  background-color: $adx-success !important;
}

#navbar-mobile {
  display: none;
}

#navbar-desktop {
  display: block;
}

#btn-logout {
  margin-left: auto;
}

@media (max-width: 830px) {

  #navbar-mobile {
    display: block;
  }

  #navbar-desktop {
    display: none;
  }


}

// @media (max-width: 768px) {
//   body {
//     font-size: 5px !important;
//   }
// }
</style>
