import axios from "axios";
import store from "@/store";
import { API_CONSTANTS } from "@/shared/generalConstants";

export default {
  login(data) {
    return new Promise(async (resolve, reject) => {
      const user = { ...data }
      axios.post(API_CONSTANTS.ENDPOINTS.AUTH.LOGIN, user)
        .then(async rta => {
          let response = rta.data;
          if (response.token && response.token != '') {
            await store.dispatch('login', response)
            resolve(true)
          }
        })
        .catch(error => {
          if (error.code == 'ERR_NETWORK') {
            reject(error.message)
          }
          reject(error.response.data.message)
        })
    })
  },
  logout() {
    return new Promise(async (resolve) => {
      // await axios.post(API_CONSTANTS.ENDPOINTS.AUTH.LOGOUT).then(async () => {
      //   await store.dispatch('logout')
      //   resolve(true)
      // }).catch(error => {
      //   reject(error)
      // })
      resolve(store.dispatch('logout'))
    })
  },
  async register(data) {
    return new Promise((resolve, reject) => {
      try {
        const user = { ...data }
        let rta = axios.post(API_CONSTANTS.ENDPOINTS.AUTH.REGISTER, user);
        resolve(rta);
      } catch (error) {
        reject(error)
      }
    })
  }
};