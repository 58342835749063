import axios from 'axios';
import { API_CONSTANTS } from '@/shared/generalConstants';

async function getAll() {
    let response = await axios.get(API_CONSTANTS.ENDPOINTS.MAESTROS.ROLES)
    return response.data
}

async function getAllActive() {
    let response = await axios.get(API_CONSTANTS.ENDPOINTS.MAESTROS.ROLES + '/activos')
    return response.data
}

async function getRole(id) {
    let response = await axios.get(`${API_CONSTANTS.ENDPOINTS.MAESTROS.ROLES}/${id}`)
    return response.data
}

async function saveRole(data) {
    return new Promise(async (resolve, reject) => {
        try {
            let response = await axios.post(API_CONSTANTS.ENDPOINTS.MAESTROS.ROLES, data)
            resolve(response.data)
        } catch (error) {
            reject(error)
        }
    })
}

async function updateRole(data, id) {
    return new Promise(async (resolve, reject) => {
        try {
            let response = await axios.put(`${API_CONSTANTS.ENDPOINTS.MAESTROS.ROLES}/${id}`, data)
            resolve(response.data)
        } catch (error) {
            reject(error)
        }
    })
}

async function deleteRole(id) {
    return new Promise(async (resolve, reject) => {
        try {
            let response = await axios.delete(`${API_CONSTANTS.ENDPOINTS.MAESTROS.ROLES}/${id}`)
            resolve(response.data)
        } catch (error) {
            reject(error)
        }
    })
}

export default {
    getAll,
    getAllActive,
    getRole,
    saveRole,
    updateRole,
    deleteRole

}