import axios from 'axios';
import { API_CONSTANTS } from '@/shared/generalConstants';

async function getAll() {
    let response = await axios.get(API_CONSTANTS.ENDPOINTS.MAESTROS.USERS)
    return response.data
}

export default {
    getAll
}